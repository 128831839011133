/*
 * Global application theme.
 * Framework overrides and customization goes here.
 */
:root {
  //theme properties
  --primary_font_color: #000000;
  --secondary_font_color: #ffffff;
  --tertiary_font_color: #74777a;
  --quaternary_font_color: #868686;
  --primary_background_color: #ffffff;
  --secondary_background_color: #1b6be3;
  --tertiary_background_color: #f2f2f2;
  --error_color: #f35f5f;
  --success_color: #2a9e10;
  --control_primary_color: #77adff;
  --main_theme_color: #1b6be3;
  --days_left_higher_color: #ffb471;
  --not_found_color: #d0d2d3;
  --subordinate_color: #d0ebfa;

  //social properties
  --facebook_blue: #3b5998;
  --twitter_blue: #1da1f2;
  --linkedin_blue: #2867b2;

  //font properties
  --primary_font: Public Sans;
  --secondary_font: Work Sans;

  //modified theme properties
  //for rgba function use only properties with _rgb suffix.
  --primary_font_color_lighten_60: lighten($primary-font-color, 60);
  --error_color_lighten_20: lighten($error-color, 20);
  --error_color_lighten_30: lighten($error-color, 30);
  --success_color_lighten_55: lighten($success-color, 55);
  --secondary_background_color_lighten_30: lighten(
    $secondary-background-color,
    30
  );
  --quaternary_font_color_lighten_15: lighten($quaternary_font_color, 15);
  --primary_background_color_darken_5: darken($primary-background-color, 5);
  --primary_background_color_darken_70: darken($primary-background-color, 70);
  --secondary_background_color_rgb: 27, 107, 227;
  --quaternary_font_color_rgb: 134, 134, 134;
  --main_theme_color_rgb: 27, 107, 227;
  --primary_background_color_rgb: 255, 255, 255;
}

$max-mobile-screen-width: 1000px;
$max-mobile-portrait-width: 576px;
$min-desktop-screen-width: 1366px;
$max-critical-mobile-width: 350px;
$app-width: 1366px;
$app-listing-width: 1100px;
$account-options-desktop-right-margin: 100px;

$primary-font: Public Sans;
$secondary-font: Work Sans;

$primary-font-color: #000000;
$secondary-font-color: #ffffff;
$tertiary-font-color: #74777a;
$quaternary_font_color: #868686;
$primary-background-color: #ffffff;
$secondary-background-color: #1b6be3;
$tertiary-background-color: #f2f2f2;
$error-color: #f35f5f;
$success-color: #2a9e10;
$facebook-blue: #3b5998;
$twitter-blue: #1da1f2;
$linkedin-blue: #2867b2;
$control-primary-color: #77adff;
$main-theme-color: #1b6be3;
$days-left-higher-color: #ffb471;
$not-found-color: #d0d2d3;
$subordinate-color: #d0ebfa;
