.accordion {
  .card {
    border: 1px solid #cee1ff;
    box-shadow: 1px 1px 10px 0 #00000026;

    .card-header {
      padding: 0;
      cursor: pointer;
      background-color: white;

      &:hover {
        background-color: var(--primary_background_color_darken_5);
      }
    }
  }
}
