.custom-html-media > .innerHTML {
  iframe {
    max-width: 100%;
  }

  font-family: var(--primary_font);
  color: var(--primary_font_color);
  font-size: 14px;
  font-weight: 200;
  margin-bottom: 1rem;

  * {
    max-width: 100%;
  }
}
