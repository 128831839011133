.carousel-caption {
  color: black;
  position: absolute;
  bottom: 5%;
}

.carousel-indicators li {
  background-color: black;
}

.carousel-control-next-icon {
  transform: scaleX(2);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23000' width='18' height='18' viewBox='0 0 24 24'%3e%3cpath d='M0 0h24v24H0z' fill='%230000'/%3e%3cpath d='M22 12l-4-4v3H3v2h15v3z'/%3e%3c/svg%3e"); // background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23000' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e")
}

.carousel-control-prev-icon {
  transform: rotateZ(180deg) scaleX(2);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23000' width='18' height='18' viewBox='0 0 24 24'%3e%3cpath d='M0 0h24v24H0z' fill='%230000'/%3e%3cpath d='M22 12l-4-4v3H3v2h15v3z'/%3e%3c/svg%3e"); // background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23000' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e")
}

// Slide animation
.carousel-item {
  transition: opacity 1s ease !important;
  display: block !important;
  opacity: 0;
}

.carousel-item.active {
  opacity: 1;
}

.carousel-control-prev,
.carousel-control-next {
  width: auto; /* Dostosuj szerokość strzałek */
  padding: 0; /* Usuń wewnętrzny odstęp */
  background: none; /* Usuń tło */
  border: none; /* Usuń obramowanie */
  display: none;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  background-color: transparent;
  border: none;
  font-size: 0;
}

.carousel-control-prev:hover,
.carousel-control-next:hover {
  background: none;
  display: none;
}

.carousel-control-prev {
  left: 30%;
}
.carousel-control-next {
  left: 40%;
}
.visually-hidden {
  color: #8f9ef3;
  position: absolute;
  left: 20%;
  top: 20px;
}
.carousel-control-prev .carousel-control-prev-icon {
  background-image: url('data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg" fill="%23808080" viewBox="0 0 8 8"><path d="M7.7 0.29a1 1 0 0 0-1.41 0L3 3.59 1.71 2.29a1 1 0 1 0-1.41 1.41l2.5');
}
// slide animation end

@media screen and (max-width: 1000px) {
  .carousel-control-prev,
  .carousel-control-next {
    top: 80vh;
  }
}
