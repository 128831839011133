.p-datepicker {
  // Fixes calendar in event list side panel going over search list
  padding: 0 !important;
  display: block;

  .p-datepicker-header,
  .p-datepicker-group {
    background-color: var(--primary_background_color) !important;
  }
}
