/*
 * Entry point of global application style.
 * Component-specific style should not go here and be included directly as part of the components.
 */

// Theme variables, must be included before the libraries to allow overriding defaults
$font-weight-bolder: bold; // to nie jest błąd! proszę nie zmieniać :P

@import "src/styles/theme-variables";
@import "src/styles/theme";
@import "src/styles/fonts";
@import "src/styles/cke-custom";

// 3rd party libraries
@import "bootstrap/scss/bootstrap.scss";
@import "@fortawesome/fontawesome-free/scss/fontawesome.scss";
@import "@fortawesome/fontawesome-free/scss/brands.scss";
@import "@fortawesome/fontawesome-free/scss/regular.scss";
@import "@fortawesome/fontawesome-free/scss/solid.scss";
@import "primeng/resources/primeng.min.css";
@import "primeicons/primeicons.css";
@import "primeng/resources/themes/nova/theme.css";
@import "material-design-icons/iconfont/material-icons.css";

// Override styles
@import "src/styles/bootstrap/bootstrap";
@import "src/styles/innerHTML/innerHTML";
@import "src/styles/ngx-file-upload/ngx-file-upload";
@import "src/styles/primeng/primeng";

body {
  margin: 0;
  padding: 0;
  height: 100%;
}

.material-icons {
  font-family: "Material Icons";
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: "liga";
  -webkit-font-smoothing: antialiased;
}
