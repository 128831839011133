.image-gallery {
  .modal-content {
    height: 100%;
    background: none;
    border: none;
  }
}

.modal-backdrop {
  z-index: 1050 !important;
}
