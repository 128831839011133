///--- Override global styles ---///
.p-carousel-container {
  .p-link {
    background-color: transparent;
    color: var(--tertiary_font_color);
    border: none !important;

    &:enabled:focus {
      box-shadow: none;
    }

    .carousel-prev-icon,
    .carousel-next-icon {
      transition: transform 0.2s;
    }

    .p-icon {
      width: 3rem;
      height: 3rem;
    }
  }
}

.p-carousel-indicators {
  .p-link {
    &:enabled:focus {
      box-shadow: none;
    }

    .p-carousel-dot-icon {
      width: 25px !important;
      height: 4px !important;
      transition: background-color 0.4s;
    }

    .pi-circle-on {
      background-color: var(--main_theme_color) !important;
    }

    .pi-circle-off {
      background-color: var(--not_found_color) !important;
    }
  }
}

///--- Override global styles end ---///

///--- Override local styles ---///
.partner-list-mobile {
  .p-carousel-container {
    align-items: center;
    justify-content: center;
    flex-direction: row;

    .p-carousel-items-content {
      height: 425px;
      width: 150px;

      .p-carousel-items-container {
        flex-direction: row;
      }
    }
  }

  @media screen and (max-width: $max-mobile-portrait-width) {
    .p-carousel-container {
      flex-direction: column;

      .p-carousel-items-content {
        .p-carousel-items-container {
          flex-direction: column;
        }
      }
    }
  }

  @media screen and (min-width: $max-mobile-portrait-width + 1) and (max-width: $min-desktop-screen-width) {
    .p-carousel-container {
      flex-direction: row;

      .p-carousel-items-content {
        height: 300px;
        width: 600px;

        .p-carousel-items-container {
          flex-direction: row;
        }
      }
    }
  }
}
///--- Override local styles end ---///
