.pagination {
  padding-right: 1rem;

  li {
    .page-link {
      z-index: unset !important;

      &:focus {
        outline: none;
        box-shadow: none;
      }
    }

    &:not(.active) .page-link {
      &:hover {
        color: var(--tertiary_font_color);
        background-color: var(--tertiary_background_color);
      }
    }

    a {
      min-width: 30px;
    }
  }

  .active a {
    z-index: unset !important;
  }
}

ngb-pagination {
  height: 50px;
  display: block;
}
