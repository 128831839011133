.ngx-file-drop__drop-zone {
  border: 1px solid var(--tertiary_font_color) !important;
  border-radius: 2px !important;
}

.ngx-error {
  .ngx-file-drop__drop-zone {
    border: 1px solid $error-color !important;
  }
}

.ngx-dirty {
  .ngx-file-drop__drop-zone {
    border: 1px solid var(--control_primary_color) !important;
  }
}
